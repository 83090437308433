
import { Prop, Vue, Component } from 'vue-property-decorator';

export interface Item {
    name: string,
    value: string | number,
    disabled?: boolean
}

@Component
export default class CustomMultiSelectBordered extends Vue {
    @Prop({
        required: false,
        type: Number,
        default: 0,
    })
    private maxSelected!: number;

    @Prop({
        required: true,
        type: Array as () => Item[],
    })
    private items!: Item[];

    @Prop({
        required: false,
        type: Array as () => Item[],
    })
    value!: Item[];

    @Prop({
        required: false,
        type: String,
    })
    private label!: string;

    @Prop({
        required: false,
        type: Boolean,
        default: true,
    })
    private showButton!: boolean;

    @Prop({
        required: false,
        type: Boolean,
        default: true,
    })
    private showSelected!: boolean;

    @Prop({
        type: String,
        required: false,
    })
    private title?: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private disabled!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private closeAfterSelect!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    isGearSelect!: boolean;

    private isExpanded: boolean = false;
    private dropdownPosition: {
        top?: string,
        left?: string,
        bottom?: string,
    } = {};

    mounted() {
        this.setDropdownPosition();
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('resize', this.handleScroll, true);
        document.body.appendChild(this.$refs.wrapper as any);
    }

    updated() {
        this.setDropdownPosition();
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll, true);
        window.removeEventListener('resize', this.handleScroll, true);
        document.body.removeChild(this.$refs.wrapper as any);
    }

    handleScroll() {
        this.setDropdownPosition();
    }

    clickOutside(e: MouseEvent) {
        const ref = this.$refs.multiSelect as HTMLDivElement;
        const path = e.composedPath() as HTMLElement[];

        const isListClick = path
            .some(el => el.className && el.className.includes('list-wrapper'));

        if (isListClick) return;

        if (ref && (ref !== e.target && !ref.contains(e.target as Node | null))) {
            this.isExpanded = false;
            document.body.removeEventListener('click', this.clickOutside);
        }
    }

    hide() {
        this.isExpanded = false;
    }

    handleSelectClick() {
        if (this.disabled) return;

        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
            document.body.addEventListener('click', this.clickOutside, true);
        } else {
            document.body.removeEventListener('click', this.clickOutside, true);
        }
    }

    handleOptionClick(clickedValue: Item) {
        const value = this.value || [];
        if (this.isDisabled(clickedValue)) {
            return;
        }
        const isSelected = value.find(option => option.value === clickedValue.value);
        let selectedOptions = [...value];

        if (!isSelected) {
            selectedOptions = selectedOptions.concat([clickedValue]);
        } else {
            selectedOptions = selectedOptions.filter(option => option.value !== clickedValue.value);
        }

        if (this.closeAfterSelect) {
            this.handleSelectClick();
        }

        this.$emit('input', selectedOptions);
    }

    get valueLength() {
        const value = this.value || [];
        const reducer = (accumulator: number, currentValue: Item) => (currentValue.disabled ? accumulator : accumulator + 1);
        return value.reduce(reducer, 0);
    }

    get disabledLength() {
        const items = this.items || [];
        const reducer = (accumulator: number, currentValue: Item) => (currentValue.disabled ? accumulator + 1 : accumulator);
        return items.reduce(reducer, 0);
    }

    handleAllClick() {
        let selectedOptions: Item[] = [];

        if (!this.isSelectedAll) {
            selectedOptions = [...this.items.filter(item => !item.disabled)];
        } else {
            selectedOptions = [];
        }

        this.$emit('input', selectedOptions);
    }

    isSelected(item: Item) {
        const value = this.value || [];
        return !!value.find(currentItem => currentItem.value === item.value);
    }

    isDisabled(item: Item) {
        return item.disabled || (this.maxSelected && this.maxSelected === this.valueLength && !this.isSelected(item));
    }

    setDropdownPosition() {
        const parentRef = this.$refs.multiSelect as HTMLDivElement;
        if (!parentRef) {
            return;
        }
        const clientRect = parentRef.getBoundingClientRect();

        if (clientRect.bottom + 300 < window.innerHeight) {
            if (this.dropdownPosition.left
            && this.dropdownPosition.top
            && clientRect.left === parseFloat(this.dropdownPosition.left)
            && clientRect.top + clientRect.height === parseFloat(this.dropdownPosition.top)) {
                return;
            }

            this.dropdownPosition = {
                top: `${(clientRect.top + clientRect.height)}px`,
                left: `${(clientRect.left)}px`,
                bottom: undefined,
            };
        } else {
            if (this.dropdownPosition.left
            && this.dropdownPosition.bottom
            && clientRect.left === parseFloat(this.dropdownPosition.left)
            && window.innerHeight - clientRect.top + 20 === parseFloat(this.dropdownPosition.bottom)) {
                return;
            }

            this.dropdownPosition = {
                top: undefined,
                bottom: `${window.innerHeight - clientRect.top + 20}px`,
                left: `${(clientRect.left)}px`,
            };
        }
    }

    get isSelectedAll() {
        return this.items.length === this.valueLength + this.disabledLength;
    }
}
