
import { Component, Vue } from 'vue-property-decorator';

import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import TabsController from '@/modules/common/components/tabs-controller.vue';
import SippCodeMappingAlertsSettings from '../components/tabs/sippcode-mapping.tab.vue';
import CarRateAlertSettings from '../components/tabs/alerts-notifications.tab.vue';

@Component({
    components: {
        PageWrapper,
        TabsController,
    },
})
export default class SettingsPage extends Vue {
    tabs = [
        SippCodeMappingAlertsSettings,
        CarRateAlertSettings,
    ];

    currentTab = +this.$route.query.tab || 0;

    setTab(index: number) {
        this.currentTab = index;
        this.updateQueryParameter();
    }

    updateQueryParameter() {
        if (this.currentTab === +this.$route.query.tab) return;

        this.$router.push({
            name: this.$route.name!,
            params: {
                ...this.$route.params,
            },
            query: { tab: String(this.currentTab) },
        });
    }
}
