var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapper',{staticClass:"settings",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('header',{staticClass:"page-header"},[_c('div',{staticClass:"page-header__left-column"},[_c('h4',{staticClass:"page-header__heading"},[_vm._v(" Settings ")])])]),_c('div',{staticClass:"settings__tabs"},_vm._l((_vm.tabs),function(tab,index){return _c('button',{key:tab.title,class:{
                    'settings__tab-trigger': true,
                    'settings__tab-trigger--active': _vm.currentTab === index,
                },attrs:{"disabled":!tab || tab.disabled},on:{"click":function($event){return _vm.setTab(index)}}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),0)]},proxy:true},{key:"main",fn:function(){return [_c('div',{staticClass:"settings__scroll-container"},[_c('TabsController',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"default",fn:function({ component }){return [_c('transition',{attrs:{"name":"settings__content","mode":"out-in"}},[(component && !component.disabled)?_c('div',{key:component.title,class:{
                            'settings__content': true,
                            'settings__content--fullwidth': component.isFullwidth,
                        }},[(component.subTitle)?_c('div',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(component.subTitle)+" ")]):_vm._e(),_c(component,{tag:"component"})],1):_vm._e()])]}}]),model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }